<template>
  <div class="flex flex-col w-full">
    <div
        :class="[isActive ? 'bg-slate-grey' : 'bg-faded-red']"
        class="flex h-50 text-white text-center items-center justify-center text-lg"
        @click="isActive = !isActive"
    >
      {{ isActive? 'Close filter panel' : 'Open filter panel' }}
    </div>
    <div class="flex flex-row flex-wrap h-full">
      <div :class="isActive ? 'two-col' : 'three-col'" class="grid flex flex-1">
        <div class="bg-faded-red m-20 flex text-center text-lg text-white">1</div>
        <div class="bg-faded-red m-20 flex text-center text-lg text-white">2</div>
        <div class="bg-faded-red m-20 flex text-center text-lg text-white">3</div>
        <div class="bg-faded-red m-20 flex text-center text-lg text-white">4</div>
        <div class="bg-faded-red m-20 flex text-center text-lg text-white">5</div>
      </div>
      <div
          class="filter-panel flex bg-slate-grey mt-20"
          :class="[isActive ? 'w-1/2' : 'width-zero']"
      >
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {
    // components
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    // computed
  },
  methods: {
    // methods
  },
};
</script>


<style lang="scss" scoped>
.filter-panel {
  transition: width 1s ease;
}
.width-zero {
  width: 0;
}
.grid {
  display: grid;
}
.two-col {
  grid-template-columns: repeat(2, 1fr);
}
.three-col {
  grid-template-columns: repeat(3, 1fr);
}
</style>
